'use client'

import React, { useState, useEffect } from 'react';
import { Label } from '@radix-ui/react-label';
import { InputField } from '../ui/input';

interface InputProps {
    label?: string;
    type?: string;
    placeholder?: string;
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isRequired?: boolean;
    requiredMessage?: string;
    width?: string;
    style?: string;
    readOnly?: boolean;
}

const Input: React.FC<InputProps> = ({
    label,
    type = 'text',
    placeholder,
    value,
    onChange,
    isRequired = true,
    requiredMessage,
    width = 'w-full',
    style = 'h-10',
    readOnly,
}) => {
    const id = label ? label.replace(/\s+/g, '-').toLowerCase() : undefined;
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (type === 'email' && value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setErrorMessage('Invalid email address');
            } else {
                setErrorMessage('');
            }
        } else {
            setErrorMessage('');
        }
    }, [type, value]);

    return (
        <div className={`${width}`}>
            <Label htmlFor={id} className="flex items-center">
                <p className="text-[8pt] font-normal mb-1">{label}</p>
                {isRequired && <p className="text-red-600 ml-1 mt-[-3px]">*</p>}
            </Label>
            <InputField
                id={id}
                className={style}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                readOnly={readOnly}
            />
            {requiredMessage?.length !== 0 && (
                <p className="text-[7pt] font-light mt-1 text-red-500">{requiredMessage}</p>
            )}
        </div>
    );
};

export default Input;