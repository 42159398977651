'use client'

import { useState, useEffect } from 'react'
import Image from 'next/image'
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import Input from '@/components/input/Input'
import PasswordInput from '@/components/input/PasswordInput'
import { toast } from 'react-hot-toast';
import { Loader2 } from 'lucide-react'
import { useMutation } from '@apollo/client'
import { USER_LOGIN } from '@/graphql/authTypeDefts'
import { useRouter } from 'next/navigation'

export default function LoginPage() {
  const router = useRouter();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({ username: '', password: '' });

  // Mutation for user login
  const [userLogin, { loading }] = useMutation(USER_LOGIN, {
    onCompleted: (data) => {
      if (data?.userLogin?.status) {
        toast.success("Login successful!");

        // Store username for "Remember Me" feature
        if (rememberMe) {
          localStorage.setItem('rememberedUsername', username);
        } else {
          localStorage.removeItem('rememberedUsername');
        }

        // Navigate to the dashboard after login
        setTimeout(() => router.push("/dashboard"), 1500);
      } else {
        toast.error(data?.userLogin?.message || "Login failed. Please try again.");
      }
    },
    onError: (error) => {
      console.error("Login Error:", error.message);
      toast.error("An error occurred during login. Please try again.");
    },
  });

  // Load remembered username (if any) on component mount
  useEffect(() => {
    const savedUsername = localStorage.getItem('rememberedUsername');
    if (savedUsername) {
      setUsername(savedUsername);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async () => {
    // Validate input fields
    const newErrors = { username: '', password: '' };
    if (!username) newErrors.username = 'Username is required';
    if (!password) newErrors.password = 'Password is required';
    setErrors(newErrors);

    if (newErrors.username || newErrors.password) {
      return; // Stop submission if validation fails
    }

    // Call login mutation
    await userLogin({
      variables: {
        input: {
          password,
          securityKey: process.env.NEXT_PUBLIC_ENDPOINT_KEY,
          userName: username,
        },
      },
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-md bg-white p-8 rounded-lg ">
        <div className="flex justify-center mb-4">
          <Image
            src="/main_logo.png"
            alt="Three Percent Trading Academy"
            width={80}
            height={80}
            priority
            className="h-auto w-[150px]"
          />
        </div>
        <h1 className="text-2xl font-bold text-center text-zinc-800 mb-2">Welcome Back</h1>
        <p className="text-center text-zinc-600 mb-4">
          Enter your credentials to access your account
        </p>
        <div className="space-y-5">
          <Input
            label="Username"
            type="text"
            value={username}
            isRequired={true}
            placeholder="Enter username"
            onChange={(e) => setUsername(e.target.value)}
            requiredMessage={errors.username}
          />
          <PasswordInput
            placeholder="Enter your password"
            value={password}
            onChange={(newPassword) => setPassword(newPassword)}
            requiredMessage={errors.password}
          />
          <div className="flex items-center space-x-2">
            <Checkbox
              id="remember"
              checked={rememberMe}
              onCheckedChange={(checked) => setRememberMe(checked as boolean)}
              className="border-zinc-300 text-yellow-500 focus:ring-yellow-500"
            />
            <label htmlFor="remember" className="text-sm text-zinc-600">Remember me</label>
          </div>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            className="w-full bg-black text-white font-semibold py-2 px-4 rounded-md transition duration-200 ease-in-out"
          >
            {loading && <Loader2 className="h-4 w-4 animate-spin mr-2" />}
            Sign In
          </Button>
        </div>
        <footer className="mt-8 text-center text-sm text-zinc-500">
          © {new Date().getFullYear()} Tang Kung Group. All rights reserved.
        </footer>
      </div>
    </div>
  );
}
