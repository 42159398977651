import { gql } from "@apollo/client";

export const USER_LOGIN = gql`
mutation UserLogin($input: LoginInput!) {
  userLogin(input: $input) {
    _id
    firstName
    lastName
    userName
    role
    accessToken
    message
    status
  }
}
`

export const USER_TRACKING_AUTH = gql`
query UserTrackingAuth {
  userTrackingAuth {
    message
    status
    accessToken
    fullName
    email
    photoURL
    payment
  }
}
`
