import React, { useState } from 'react';
import { InputField } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Label } from '@radix-ui/react-label';

interface PasswordInputProps {
  placeholder?: string;
  value: string;
  requiredMessage?: string;
  onChange?: (value: string) => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  placeholder = 'Enter password',
  onChange,
  requiredMessage,
  value
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(value);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    onChange && onChange(newPassword);
  };

  return (
    <div>
      <Label htmlFor="Password">
        <p className="text-[8pt] font-normal mb-1">Password</p>
      </Label>
      <div className="relative h-[50px]">
        <InputField
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          value={password}
          onChange={handlePasswordChange}
          className="pr-10 h-full tracking-wide"
        />
        <Button
          type="button"
          variant="ghost"
          size="icon"
          className="absolute right-0 top-0 h-full"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? (
            <FaEyeSlash className="h-5 w-5 text-gray-600" />
          ) : (
            <FaEye className="h-5 w-5 text-gray-600" />
          )}
          <span className="sr-only">
            {showPassword ? 'Hide password' : 'Show password'}
          </span>
        </Button>
      </div>
      {requiredMessage && (
        <p className="text-[7pt] font-light mt-1 text-red-500">{requiredMessage}</p>
      )}
    </div>
  );
};

export default PasswordInput;